<template>
<div class="industrial">
  <div class="banner"></div>
  <div class="body">
    <el-breadcrumb style="font-size: 16px;" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/software' }">软件开发</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/software/Industrial' }">工业物联网</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="llot">
      <p class="llot_title">通过 IIoT 解决方案释放潜力</p>
      <div class="llot_module">
        <div class="llot_module_box" v-for="(item,index) in llotBox" :key="index">
          <div class="llot_module_box_text">
            <p class="llot_module_box_text_name">{{item.name}}</p>
            <p class="llot_module_box_text_title">{{item.title}}</p>
            <span class="llot_module_box_text_info">{{item.info}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="scene">
      <p class="scene_title" ref="sceneTitle">工业物联网应用场景</p>
    </div>
  </div>
  <div class="scene_page_banner">
    <div class="scene_page" ref="appInfoTitle">
      <div class="scene_page_single" v-for="items in rangeIcon" :key="items.id">
        <div class="scene_page_single_box">
          <span class="scene_page_span" style="margin-top: 90px;">{{items.title}}</span>
          <span class="scene_page_span">一</span>
        </div>
      </div>
    </div>
    <div class="scene_page" ref="appInfoTitleBox">
      <div class="scene_page_single_o" v-for="items in rangeIcon_o" :key="items.id">
        <div class="scene_page_single_box">
          <span class="scene_page_span" style="margin-top: 90px;">{{items.title}}</span>
          <span class="scene_page_span">一</span>
        </div>
      </div>
    </div>
  </div>
  <div class="cost">
    <p class="cost_title" ref="costTitle">用例快速提升价值</p>
    <div class="cost_module" ref="costModule">
      <div class="cost_module_box" v-for="(item,index) in costBox" :key="index">
        <img :src="item.src"/>
        <p class="cost_module_box_title">{{item.title}}</p>
        <p class="cost_module_box_info">{{item.info}}</p>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'industrial',
  data () {
    return {
      llotBox: [
        { name: 'FULLY INCREASE INCOME', title: '充分提高收入', info: '拓展新市场、提高吞吐量并解锁新业务模式，例如产品即服务。' },
        { name: 'REDUCE HOURS', title: '缩短时间', info: '快速开发应用程序，加快产品上市速度。使用工业物联网平台打包并扩展旧有资产以实现新功能。' },
        { name: 'COST REDUCTION', title: '降低成本', info: '利用来自互联产品和系统的数据，提高生产力和效率，同时降低成本。' },
        { name: 'IMPROVE QUALITY', title: '改善质量', info: '让产品、服务和工厂作业更安全而且可扩展。提高服务质量、可靠性和满意度。' }
      ],
      rangeIcon: [
        { title: '智慧焊接', id: 1 },
        { title: '智慧煤炭', id: 2 },
        { title: '智慧油田', id: 3 },
        { title: '数字油库', id: 4 }
      ],
      rangeIcon_o: [
        { title: '智慧水厂', id: 1 },
        { title: '智慧工厂', id: 2 },
        { title: '车联网', id: 3 },
        { title: '智慧城市', id: 4 }
      ],
      costBox: [
        { src: require('@/assets/img/industrial/Industry_1.png'), title: '产品即服务', info: '借助新的产品功能和创新服务提升客户体验并推动需求增长。         ' },
        { src: require('@/assets/img/industrial/Industry_2.png'), title: '远程状态监控', info: '预防停机并大幅减少现场服务次数，提高客户成功可能性和满意度。 ' },
        { src: require('@/assets/img/industrial/Industry_3.png'), title: '数字化工作说明', info: '为您的一线员工提供所需的数字化工具和数据，以提高安全性和生产效率。 ' },
        { src: require('@/assets/img/industrial/Industry_4.png'), title: '工厂基准测试', info: '车间的进步始于 KPI 的改善。使用工厂基准测试进行一致的性能分析。' }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const __currentScroll = window.pageYOffset
      // 此处数值越大，那么触发事件就越早
      if (__currentScroll >= this.$refs.sceneTitle.offsetTop - 850) {
        if (this.$refs.sceneTitle.className === 'scene_title') {
          this.$refs.sceneTitle.className = this.$refs.sceneTitle.className + ' animated fadeInUpBig'
          this.$refs.appInfoTitle.className = this.$refs.appInfoTitle.className + ' animated fadeInDownBig'
          this.$refs.appInfoTitleBox.className = this.$refs.appInfoTitleBox.className + ' animated fadeInDownBig'
        }
      }
      if (__currentScroll >= this.$refs.costTitle.offsetTop - 650) {
        if (this.$refs.costTitle.className === 'cost_title') {
          this.$refs.costTitle.className = this.$refs.costTitle.className + ' animated fadeInUpBig'
          this.$refs.costModule.className = this.$refs.costModule.className + ' animated fadeInDownBig'
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.industrial{
  .banner {
    height: 500px;
    background: url('~@/assets/img/industrial/inner-banner5.jpg') no-repeat center;
    background-size: 100% 100%;
    }
  .body{
    width: 1400px;
    padding-top: 39px;
    margin: auto;
    .llot{
      width: 100%;
      height: 445px;
      margin-top: 39px;
      .llot_title{
        font-size: 36px;
        text-align: center;
      }
      .llot_module{
        height: 335px;
        width: 100%;
        padding-top: 60px;
        display: flex;
        justify-content: space-between;
        .llot_module_box{
          width: 335px;
          height: 335px;
          background-color: #f5f7fa;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          .llot_module_box_text{
            width: 229px;
            margin-top: 50px;
            text-align: center;
            .llot_module_box_text_name{
              font-size: 18px;
            }
            .llot_module_box_text_title{
              font-size: 28px;
              font-weight: bold;
              margin-top: 20px;
            }
            .llot_module_box_text_info{
              font-size: 14px;
              line-height: 30px;
              margin-top: 29px;
              display: inline-block;
            }
          }
        }
        .llot_module_box:hover{
          background-color: #ffff;
          box-shadow: 4px 4px 12px 0px
          rgba(219, 219, 219, 0.57);
          background:  url('~@/assets/img/industrial/Industry_bg.png')no-repeat;
          background-position: bottom center;
          .llot_module_box_text_title{
            color: #378eff;
          }
        }
      }
    }
    .scene{
      width: 100%;
      .scene_title{
        font-size: 36px;
        text-align: center;
        margin-top: 52px;
      }
    }
  }
  .scene_page_banner{
    width: 100%;
    height: 540px;
    margin-top: 60px;
    overflow: hidden;
    .scene_page {
      height: 260px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      .scene_page_single {
        width: 460px;
        height: 100%;
        background: url('~@/assets/img/industrial/Industry_case1.png') no-repeat 100% 100%;
        border-radius: 8px;
        .scene_page_single_box {
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,.4);
          border-radius: 8px;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          font-family: MicrosoftYaHei;
          line-height: 50px;
          .scene_page_span{
            font-size: 30px;
            letter-spacing: 3px;
            color: #ffffff;
          }
        }
      }
      .scene_page_single:hover {
        .scene_page_single_box{
          background-color: rgba(0,0,0,0);
        }
      }
      .scene_page_single_o {
        width: 460px;
        height: 100%;
        background: url('~@/assets/img/industrial/Industry_case5.png') no-repeat 100% 100%;
        border-radius: 8px;
        .scene_page_single_box {
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,.4);
          border-radius: 8px;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          font-family: MicrosoftYaHei;
          line-height: 50px;
          .scene_page_span{
            font-size: 30px;
            letter-spacing: 3px;
            color: #ffffff;
          }
        }
      }
      .scene_page_single_o:hover {
        .scene_page_single_box{
          background-color: rgba(0,0,0,0);
        }
      }
      .scene_page_single:nth-child(2) {
        background: url('~@/assets/img/industrial/Industry_case2.png') no-repeat 100% 100%;
      }
      .scene_page_single_o:nth-child(2) {
        background: url('~@/assets/img/industrial/Industry_case6.png') no-repeat 100% 100%;
      }
      .scene_page_single:nth-child(3) {
        background: url('~@/assets/img/industrial/Industry_case3.png') no-repeat 100% 100%;
      }
      .scene_page_single_o:nth-child(3) {
        background: url('~@/assets/img/industrial/Industry_case7.png') no-repeat 100% 100%;
      }
      .scene_page_single:nth-child(4) {
        background: url('~@/assets/img/industrial/Industry_case4.png') no-repeat 100% 100%;
      }
      .scene_page_single_o:nth-child(4) {
        background: url('~@/assets/img/industrial/Industry_case8.png') no-repeat 100% 100%;
      }
    }
  }
  .cost{
    height: 414px;
    width: 1400px;
    margin: auto;
    overflow: hidden;
    .cost_title{
      font-size: 36px;
      text-align: center;
      padding-top: 52px;
    }
    .cost_module{
      width: 100%;
      height: 260px;
      margin-top: 54px;
      display: flex;
      justify-content: space-between;
      .cost_module_box{
        width: 335px;
        height: 100%;
        background-color: #fff;
        text-align: center;
        border-radius: 6px;
        img{
          padding-top: 20px;
        }
        .cost_module_box_title{
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 2px;
          padding-top: 42px;
        }
        .cost_module_box_info{
          width: 238px;
          margin: auto;
          font-size: 14px;
          padding-top: 19px;
        }
      }
      .cost_module_box:hover{
        box-shadow: 3px 5px 13px 1px
    rgba(0, 0, 0, 0.06);
        margin-top: -20px;
      }
    }
  }
}
</style>
