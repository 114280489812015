<template>
    <!-- 头部 -->
  <div class="common_center">
    <div class="banner"></div>
    <div class="icon_show">
      <el-breadcrumb style="font-size: 16px;" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/software' }">软件开发</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/software/supplychain' }">供应链金融</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="mobile_title" ref="mobileTitle">
        <p class="mobile_title_one">供应链金融优势</p>
      </div>
      <div class="company_good_info_module2" ref="mobileBox">
        <div :class="isVal === index ? 'company_good_info_module2_box box_ts' : 'company_good_info_module2_box'"  v-for="(item, index) in goodInfo" :key="index" @mouseover="hoverModule(index)" @mouseout="isVal = null">
          <img :src="isVal === index ? item.hoverSrc : item.src">
          <span class="company_good_info_module2_box_span1">{{item.title}}</span>
          <span class="company_good_info_module2_box_span2">{{item.text}}</span>
        </div>
      </div>
      <div class="mobile_title" ref="mobileTitle1">
        <p class="mobile_title_one">供应链金融应用场景</p>
      </div>
      <div class="classify_page_banner" ref="mobileBox1">
        <div class="classify_page" ref="app_info_title">
          <div class="classify_page_single" v-for="items in rangeIcon" :key="items.id">
            <div class="classify_page_single_box">
              <span class="classify_page_span" style="margin-top: 90px;">{{items.title}}</span>
              <span class="classify_page_span">一</span>
            </div>
          </div>
        </div>
        <div class="classify_page" ref="app_info_title_box">
          <div class="classify_page_single_o" v-for="items in rangeIcon_o" :key="items.id">
            <div class="classify_page_single_box">
              <span class="classify_page_span" style="margin-top: 90px;">{{items.title}}</span>
              <span class="classify_page_span">一</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <!-- 路由出口 -->
</template>
<script>
export default {
  name: 'supplychain',
  data () {
    return {
      isVal: null,
      goodInfo: [
        {
          src: require('@/assets/img/softWare/supplychain/Supply-chain_icon1.png'),
          hoverSrc: require('@/assets/img/softWare/supplychain/Supply-chain_icon_hover1.png'),
          title: '区块链存证',
          text: '打造基于区块链的分布式商业数据联合应用平台，实现资产准实时流转清分，上链数据记录与存储，实现多机构间文件互传。 '
        },
        {
          src: require('@/assets/img/softWare/supplychain/Supply-chain_icon2.png'),
          hoverSrc: require('@/assets/img/softWare/supplychain/Supply-chain_icon_hover2.png'),
          title: '人脸识别',
          text: '基于活体检测的人脸识别技术手段有效辅助企业线上核身。'
        },
        {
          src: require('@/assets/img/softWare/supplychain/Supply-chain_icon3.png'),
          hoverSrc: require('@/assets/img/softWare/supplychain/Supply-chain_icon_hover3.png'),
          title: '大数据风控',
          text: '集成丰富数据源及自有数据积累成熟的大数据对企业进行经营分析，有效辅助风控。'
        },
        {
          src: require('@/assets/img/softWare/supplychain/Supply-chain_icon4.png'),
          hoverSrc: require('@/assets/img/softWare/supplychain/Supply-chain_icon_hover4.png'),
          title: '微服务部署',
          text: '高容错，高扩展，技术选型灵活，部署简单。'
        }
      ],
      // 开发范围
      rangeIcon: [
        {
          title: '通信',
          id: 1
        },
        {
          title: '汽车',
          id: 2
        },
        {
          title: '建筑',
          id: 3
        },
        {
          title: '医疗',
          id: 4
        }
      ],
      rangeIcon_o: [
        {
          title: '工业',
          id: 1
        },
        {
          title: '贸易',
          id: 2
        },
        {
          title: '物流',
          id: 3
        },
        {
          title: '金融',
          id: 4
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    hoverModule (val) {
      this.isVal = val
    },
    handleScroll () {
      const __currentScroll = window.pageYOffset
      // 此处数值越大，那么触发事件就越早
      if (__currentScroll >= this.$refs.mobileTitle.offsetTop - 600) {
        const DomArr = this.$refs.mobileTitle.className.split(' ')
        if (!DomArr.some(item => item === 'animated')) {
          if (this.$refs.mobileTitle.className === 'mobile_title') {
            this.$refs.mobileTitle.className = this.$refs.mobileTitle.className + ' animated bounceInUp'
            this.$refs.mobileBox.className = this.$refs.mobileBox.className + ' animated zoomInDown'
          }
        }
      }
      if (__currentScroll >= this.$refs.mobileTitle1.offsetTop - 750) {
        const DomArr = this.$refs.mobileTitle1.className.split(' ')
        if (!DomArr.some(item => item === 'animated')) {
          if (this.$refs.mobileTitle1.className === 'mobile_title') {
            this.$refs.mobileTitle1.className = this.$refs.mobileTitle1.className + ' animated bounceInUp'
            this.$refs.mobileBox1.className = this.$refs.mobileBox1.className + ' animated zoomInDown'
          }
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.common_center{
  overflow: hidden;
  .banner {
    height: 500px;
    background: url('~@/assets/img/softWare/supplychain/inner_banner7.jpg') no-repeat center;
    background-size: 100% 100%;
  }
  .icon_show {
    width: 1400px;
    padding-top: 39px;
    margin: auto;
    overflow: hidden;
    .mobile_title{
      margin-top: 60px;
      margin-bottom: 60px;
      width: 100%;
      text-align: center;
      font-family: MicrosoftYaHei;
      color: #333333;
      .mobile_title_one {
        font-size: 36px;
      }
    }
    .company_good_info_module2{
      height: 400px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .company_good_info_module2_box{
        width: 335px;
        height: 100%;
        border: solid 1px #e5e5e5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: MicrosoftYaHei;
        color: #333333;
        .company_good_info_module2_box_span1{
          margin-top: 50px;
          margin-bottom: 30px;
          font-size: 24px;
        }
        .company_good_info_module2_box_span2{
          width: 243px;
          font-size: 14px;
        }
      }
      .box_ts {
        background-color: #378eff;
        .company_good_info_module2_box_span1 {
          color: #fff;
        }
        .company_good_info_module2_box_span2 {
          color: #fff;
        }
      }
    }
    .classify_page_banner{
      width: 100%;
      height: 515px;
      .classify_page {
        height: 250px;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        .classify_page_single {
          width: 340px;
          height: 100%;
          background: url('~@/assets/img/softWare/supplychain/Supply-chain_bg1.jpg') no-repeat 100% 100%;
          .classify_page_single_box {
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.4);
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            font-family: MicrosoftYaHei;
            line-height: 50px;
            .classify_page_span{
              font-size: 30px;
              letter-spacing: 3px;
              color: #ffffff;
            }
          }
        }
        .classify_page_single:hover {
          .classify_page_single_box{
            background-color: rgba(0,0,0,0);
          }
        }
        .classify_page_single_o {
          width: 340px;
          height: 100%;
          background: url('~@/assets/img/softWare/supplychain/Supply-chain_bg5.jpg') no-repeat 100% 100%;
          .classify_page_single_box {
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.4);
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            font-family: MicrosoftYaHei;
            line-height: 50px;
            .classify_page_span{
              font-size: 30px;
              letter-spacing: 3px;
              color: #ffffff;
            }
          }
        }
        .classify_page_single_o:hover {
          .classify_page_single_box{
            background-color: rgba(0,0,0,0);
          }
        }
        .classify_page_single:nth-child(2) {
          background: url('~@/assets/img/softWare/supplychain/Supply-chain_bg2.jpg') no-repeat 100% 100%;
        }
        .classify_page_single_o:nth-child(2) {
          background: url('~@/assets/img/softWare/supplychain/Supply-chain_bg6.jpg') no-repeat 100% 100%;
        }
        .classify_page_single:nth-child(3) {
          background: url('~@/assets/img/softWare/supplychain/Supply-chain_bg3.jpg') no-repeat 100% 100%;
        }
        .classify_page_single_o:nth-child(3) {
          background: url('~@/assets/img/softWare/supplychain/Supply-chain_bg7.jpg') no-repeat 100% 100%;
        }
        .classify_page_single:nth-child(4) {
          background: url('~@/assets/img/softWare/supplychain/Supply-chain_bg4.jpg') no-repeat 100% 100%;
        }
        .classify_page_single_o:nth-child(4) {
          background: url('~@/assets/img/softWare/supplychain/Supply-chain_bg8.jpg') no-repeat 100% 100%;
        }
      }
    }
  }
}
</style>
